export const isModalOpen = (modal) => modal.classList.contains('modal_on');
export const closeModal = (modal) => {
    modal.classList.replace('modal_on', 'modal_off');
    document.body.classList.remove('overflow-hidden');
};
export const openModal = (modal) => {
    modal.classList.replace('modal_off', 'modal_on');
    document.body.classList.add('overflow-hidden');
};
export const toggleModal = (modal) => {
    if (isModalOpen(modal)) {
        closeModal(modal);
    }
    else {
        openModal(modal);
    }
};
