import { openModal } from '../utils/modals_handler';
document.addEventListener('DOMContentLoaded', () => {
    const makeAppointmentButtons = Array.from(document.querySelectorAll('.makeAppointmentButton'));
    const makeAppointmentModal = document.querySelector('#modalMakeAppointment');
    // Добавляем ф-ию открытия модалки на все кнопки "Записаться на приём"
    makeAppointmentButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
            event.stopPropagation();
            openModal(makeAppointmentModal);
        });
    });
});
